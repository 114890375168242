import { gql } from "@apollo/client";
import { AtlasHovercardRootProps, Hovercard, Icon } from "@resource/atlas";
import { atlasCalendarAlt } from "@resource/atlas/icons";
import { replacePlaceholderValues } from "@resource/common";
import clsx from "clsx";
import { UpcomingInterviewsHovercardFragment } from "generated/schemaTypes";
import moment from "moment-timezone";
import { useMemo } from "react";

import SafeHtml from "../Generic/SafeHtml";

const UPCOMING_INTERVIEWS_FRAGMENT = gql`
  fragment UpcomingInterviewsHovercardFragment on Event {
    id
    displayTitle
    replacementData
    startTime
    endTime
  }
`;

type UpcomingInterviewsHovercardProps = {
  upcomingInterviews: UpcomingInterviewsHovercardFragment[];
} & Omit<AtlasHovercardRootProps, "children">;

function UpcomingInterviewsHovercard({
  upcomingInterviews,
  ...rest
}: UpcomingInterviewsHovercardProps) {
  const interviews = useMemo(
    () =>
      upcomingInterviews.map((upcomingInterview) => ({
        ...upcomingInterview,
        title: replacePlaceholderValues(
          upcomingInterview.displayTitle,
          upcomingInterview.replacementData
        ),
      })),
    [upcomingInterviews]
  );

  const baseHoverIconStyles = clsx(
    "rounded-full ring-[.0938rem] ring-white bg-red-500 absolute"
  );

  if (interviews.length === 0) {
    return null;
  }

  return (
    <Hovercard.Root {...rest}>
      <Hovercard.Anchor>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <div className="relative" onClick={(e) => e.stopPropagation()}>
          {interviews.length > 1 ? (
            <div
              className={clsx(
                baseHoverIconStyles,
                "w-[14px] h-[14px] right-[-0.25rem] top-[-0.25rem] justify-center items-center flex"
              )}
            >
              <p className="text-white text-[8px] text-body-sm-heavy">
                {interviews.length}
              </p>
            </div>
          ) : (
            <div
              className={clsx(
                baseHoverIconStyles,
                "w-2 h-2 right-[-0.125rem] top-[-0.125rem]"
              )}
            />
          )}
          <Icon
            className="text-subtle"
            content={atlasCalendarAlt}
            size="medium"
          />
        </div>
      </Hovercard.Anchor>
      <Hovercard.Content onClick={(e) => e.stopPropagation()}>
        <Hovercard.Heading className="text-body-md-heavy text-dark mb-2">
          Upcoming interviews
        </Hovercard.Heading>
        {interviews.map((interview, index) => (
          <>
            <div className="pl-0">
              <SafeHtml
                html={interview.title}
                className="text-body-md text-dark"
                allowedTags={["span"]}
              />
              <div className="text-body-md text-subtle">
                {moment(interview.startTime).format(
                  "dddd, MMMM Do \u00b7 h:mm"
                )}{" "}
                - {moment(interview.endTime).format("h:mma")}
              </div>
            </div>
            {index !== interviews.length - 1 && (
              <div className="w-full h-[1px] bg-dark-gray-500 opacity-[8%] my-2" />
            )}
          </>
        ))}
      </Hovercard.Content>
    </Hovercard.Root>
  );
}

UpcomingInterviewsHovercard.fragments = {
  interview: UPCOMING_INTERVIEWS_FRAGMENT,
};

export default UpcomingInterviewsHovercard;
